import { useEffect, useState } from 'react';
import { Container, Header } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { EPICS_PATH_CONTINUE } from 'volto-epics-addon/constants';
import { TASK_UPLOAD_PROMPT } from 'volto-base-addon/constants';
import { completeTask, useTask } from 'volto-epics-addon/helpers';
import UploadStepsContainer from '@package/components/theme/FileUpload/UploadStepsContainer';
import {
  MEDIA_FILE_AUDIO_TYPES,
  MEDIA_FILE_VIDEO_TYPES,
} from '@package/constants/MediaFileMimeTypes';
import Helmet from '@plone/volto/helpers/Helmet/Helmet';
import { Dashboard } from '@uppy/react';
import Webcam from '@uppy/webcam';
import Audio from '@uppy/audio';
import ScreenCapture from '@uppy/screen-capture';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';

const Page = () => {
  const history = useHistory();
  const task = useTask(TASK_UPLOAD_PROMPT);
  const token = useSelector((state) => state.userSession.token);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [uppy, setUppy] = useState(null);

  useEffect(() => {
    setIsAuthenticated(!!token);
    setIsVisible(true);
  }, [token]);

  useEffect(() => {
    if (task?.completed === true) {
      history.push(EPICS_PATH_CONTINUE);
    }
  }, [task, history]);

  useEffect(() => {
    const uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: MEDIA_FILE_AUDIO_TYPES.concat(MEDIA_FILE_VIDEO_TYPES),
      },
    })
      .use(Webcam)
      .use(Audio)
      .use(ScreenCapture);
    uppy.use(XHRUpload, {
      endpoint: null,
      method: 'PUT',
      formData: false,
      getResponseData: () => {
        return { url: '' };
      },
    });
    uppy.on('complete', (result) => {
      if (result.failed.length === 0) {
        const fname = result.successful[0].name;
        completeTask(TASK_UPLOAD_PROMPT, { filename: fname });
      }
    });
    setUppy(uppy);
  }, []);

  useEffect(() => {
    if (!uppy) return;
    uppy.getPlugin('XHRUpload').setOptions({
      endpoint: task?.variables?.presignedUploadUrl,
    });
  }, [task, uppy]);

  return (
    <div>
      <Helmet>
        <title>Media File Upload</title>
      </Helmet>
      {isVisible ? (
        isAuthenticated ? (
          <Container className="file-upload-prompt-container">
            <UploadStepsContainer currentStep={1} totalSteps={4} />
            <Header
              style={{
                margin: '1em auto',
                display: 'block',
                textAlign: 'center',
              }}
            >
              Media upload
            </Header>
            {!task?.variables?.presignedUploadUrl ? (
              <></>
            ) : (
              <Dashboard
                uppy={uppy}
                doneButtonHandler={null}
                proudlyDisplayPoweredByUppy={false}
              ></Dashboard>
            )}
          </Container>
        ) : (
          <Container className="file-upload-prompt-container">
            <Header> You must log in first. </Header>
          </Container>
        )
      ) : (
        <Container className="file-upload-prompt-container"></Container>
      )}
    </div>
  );
};

export default Page;
