/**
 * VideoJS component.
 * @module components/manage/VideoJS/VideoJS
 */
import { useEffect, useState, useRef, useCallback } from 'react';
import videojs from 'video.js';
import 'videojs-hls-quality-selector';
import 'videojs-contrib-quality-levels';
import 'videojs-sprite-thumbnails';
import './plugins/videojs-hotkeys.js';
import './video-js.css';
import 'videojs-offset';
import 'videojs-awesome-watermark';
import 'videojs-awesome-watermark/dist/videojs-awesome-watermark.css';
import mime from 'mime-types';
import { has, omit } from 'lodash';
import request from 'superagent';
import { isInternalURL } from '@plone/volto/helpers';
import {
  MEDIA_PAGE_WOWZA_ROUTE,
  MEDIA_PAGE_SUBTITLES_ROUTE,
  MEDIA_PAGE_STORYBOARDS_ROUTE,
} from 'volto-base-addon/constants';
import { useDispatch } from 'react-redux';
import { getContent } from '@plone/volto/actions';
import config from '@plone/volto/registry';
import { v4 as uuid } from 'uuid';
import cx from 'classnames';
import { flattenToAppURL } from '@plone/volto/helpers';

const VideoJS = (props) => {
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const {
    options,
    onReady,
    onError,
    selected,
    parentUid,
    parentType,
    parentItems,
  } = props;
  const [processedSources, setProcessedSources] = useState(null);

  // Filter options to avoid XSS injection ('languages' option problem)
  const filterOptions = (options) => {
    return Object.keys(options)
      .filter((key) => config.settings.videoJSAllowedOptions.includes(key))
      .reduce((obj, key) => {
        obj[key] = options[key];
        return obj;
      }, {});
  };

  const filteredOptions = filterOptions(options);
  const getStreamUrl = async (objectPath, objectId) => {
    const { body } = await request.get(
      `${config.settings.publicPath}/${MEDIA_PAGE_WOWZA_ROUTE}/${objectPath}/${objectId}`,
    );

    if (has(body, 'url')) {
      return body.url;
    }

    return null;
  };

  const getStoryboards = async (mediaPageUid) => {
    const { body } = await request.get(
      `${config.settings.publicPath}/${MEDIA_PAGE_STORYBOARDS_ROUTE}/${mediaPageUid}`,
    );

    if (has(body, 'storyboards')) {
      return body['storyboards'];
    }

    return null;
  };

  const getSubtitlesPresignedUrl = async (mediapageUid, subtitlesId) => {
    const { body } = await request.get(
      `${config.settings.publicPath}/${MEDIA_PAGE_SUBTITLES_ROUTE}/${mediapageUid}/get/${subtitlesId}`,
    );

    if (has(body, 'presigned_url')) {
      return body;
    }

    return null;
  };

  const getSingleSource = async (sourceUrl, sourceType) => {
    // If no sourceType, assume it's a path.
    if (!sourceType) {
      if (isInternalURL(sourceUrl)) {
        const contentUrl = flattenToAppURL(sourceUrl);
        return await dispatch(getContent(contentUrl, null, 'compare_to', null))
          .then(async (content) => {
            if (content !== null && content['@type'] === 'media_page') {
              const streamUrl = await getStreamUrl(
                content['UID'],
                content['s3_object_id'],
              );
              if (streamUrl) {
                return {
                  src: streamUrl,
                  type: mime.lookup(streamUrl),
                };
              }
            }
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
      // Perhaps this is a media page references by UID
    } else if (sourceType === 'UID') {
      const uid = sourceUrl;
      const searchUrl = `${config.settings.apiPath}/++api++/@search?UID=${uid}&metadata_fields=s3_object_id`;

      const response = await fetch(searchUrl, {
        headers: {
          Accept: 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      if (!responseData.items || responseData.items.length === 0) {
        throw new Error('No content found with the provided UID');
      }
      const content = responseData.items[0];
      if (content['@type'] !== 'media_page') {
        throw new Error('The found content is not a media page');
      }
      const streamUrl = await getStreamUrl(sourceUrl, content['s3_object_id']);
      if (streamUrl) {
        return {
          src: streamUrl,
          type: mime.lookup(streamUrl),
        };
      } else {
        throw new Error('Could not find the stream url');
      }
    }
    // Otherwise, return as it is
    return {
      src: sourceUrl,
      type: sourceType,
    };
  };

  const getSourcesFromOptions = useCallback(async (options) => {
    const sourcesObj = {
      sources: [],
    };
    if (options?.sources?.length) {
      const filteredSources = options.sources.filter(
        (source) => source.src !== '',
      );

      if (!filteredSources.length) {
        return;
      }

      for (let idx = 0; idx < filteredSources.length; idx++) {
        const sourceUrl = filteredSources[idx].src;
        const sourceType = filteredSources[idx].type;
        const source = await getSingleSource(sourceUrl, sourceType);
        if (source) {
          sourcesObj.sources.push(source);
        }
      }
    }
    return sourcesObj;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let unmount = false;

    if (processedSources === null && !unmount) {
      let result = null;

      (async () => {
        result = await getSourcesFromOptions(filteredOptions);

        if (!unmount) {
          setProcessedSources(result);
        }
      })();
    }

    return () => {
      unmount = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (processedSources !== null) {
      if (!playerRef.current) {
        // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
        const videoElement = document.createElement('video-js');

        videoElement.className = 'video-js vjs-big-play-centered';
        videoElement.style.cssText = 'margin: auto';

        videoRef.current.appendChild(videoElement);
        playerRef.current = videojs(
          videoElement,
          {
            // Let's omit original sources and put our own because of processing urls to get streamUrl in case of media_page
            ...omit(filteredOptions, ['sources']),
            ...processedSources,
            language: 'fi',
            restoreEl: true,
          },
          () => {
            playerRef.current.hlsQualitySelector({
              displayCurrentQuality: true,
            });
            videojs.addLanguage('fi', {
              Play: 'Toisto',
              Pause: 'Tauko',
              'Current Time': 'Tämänhetkinen aika',
              Duration: 'Kokonaisaika',
              'Remaining Time': 'Jäljellä oleva aika',
              'Stream Type': 'Lähetystyyppi',
              LIVE: 'LIVE',
              Loaded: 'Ladattu',
              Progress: 'Edistyminen',
              Fullscreen: 'Koko näyttö',
              'Non-Fullscreen': 'Koko näyttö pois',
              Mute: 'Ääni pois',
              Unmute: 'Ääni päällä',
              'Playback Rate': 'Toistonopeus',
              Subtitles: 'Tekstitys',
              'subtitles off': 'Tekstitys pois',
              Captions: 'Tekstitys',
              'captions off': 'Tekstitys pois',
              Chapters: 'Kappaleet',
              'You aborted the media playback':
                'Olet keskeyttänyt videotoiston.',
              'A network error caused the media download to fail part-way.':
                'Verkkovirhe keskeytti videon latauksen.',
              'The media could not be loaded, either because the server or network failed or because the format is not supported.':
                'Videon lataus ei onnistunut joko palvelin- tai verkkovirheestä tai väärästä formaatista johtuen.',
              'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.':
                'Videon toisto keskeytyi, koska media on vaurioitunut tai käyttää käyttää toimintoja, joita selaimesi ei tue.',
              'No compatible source was found for this media.':
                'Tälle videolle ei löytynyt yhteensopivaa lähdettä.',
            });

            const player = playerRef.current;
            player.on('loadedmetadata', async () => {
              const duration = player.duration();
              if (parentType === 'media_page') {
                const isVideo = parentItems.filter(
                  (item) =>
                    item['@type'] === 'transcoding' && item['title'] === '360p',
                ).length;

                if (isVideo) {
                  // Get all storyboards
                  const storyboards = await getStoryboards(parentUid);

                  if (storyboards) {
                    player.spriteThumbnails({
                      urlArray: storyboards,
                      width: 240,
                      height: 135,
                      columns: 3,
                      rows: 3,
                      interval: duration < 2 * 60 ? 5 : 10,
                    });
                  }
                }
              }

              player.hotkeys({
                seekStep: 5,
              });

              if (filteredOptions.watermark?.enabled) {
                // This plugin has few bugs (for example *without text image won't be shown, *text's font-size works only with % )
                player.awesomeWatermark({
                  fontSize: 100,
                  fontColor: 'white',
                  opacity: filteredOptions.watermark.opacity,
                  position: filteredOptions.watermark.position,
                  type: filteredOptions.watermark.type,
                  text: filteredOptions.watermark.text,
                  image: filteredOptions.watermark.image
                    ? `data:${filteredOptions.watermark.image['content-type']};${filteredOptions.watermark.image.encoding},${filteredOptions.watermark.image.data}`
                    : '',
                });
              }

              if (filteredOptions['timeRange']) {
                player.offset({
                  start: filteredOptions['timeRange'].min,
                  end: filteredOptions['timeRange'].max,
                  restart_beginning: false,
                });
              }
              const optionsSubtitleLength = filteredOptions?.subtitles?.length;

              if (optionsSubtitleLength) {
                for (const [
                  index,
                  subtitle,
                ] of filteredOptions.subtitles.entries()) {
                  if (subtitle.length) {
                    await parseSubtitles(
                      player,
                      subtitle,
                      index === optionsSubtitleLength - 1,
                    );
                  }
                }
              } else {
                onReady(playerRef.current, false);
              }
            });

            player.on('error', () => {
              onError(player);
            });
          },
        );
      }
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerRef, processedSources]);

  const parseSubtitles = async (player, subtitle, isLastElement) => {
    const subtitleContentId = subtitle[0]['@id'];
    const remoteTrackId = `${config.settings.remoteTextTrackIdPrefix}${subtitle[0]['@id']}`;

    const remoteTrack = player.addRemoteTextTrack({
      label: subtitle[0].language,
      id: remoteTrackId,
    });

    const textTrack = remoteTrack.track;
    const parser = new window.WebVTT.Parser(
      window,
      window.vttjs,
      window.WebVTT.StringDecoder(),
    );

    parser.oncue = (cue) => {
      cue.id = uuid();
      textTrack.addCue(cue);
    };

    parser.onparsingerror = (error) => {
      // eslint-disable-next-line no-console
      console.log(error);
    };

    parser.onflush = () => {
      if (isLastElement) {
        onReady(player, true);
      }
    };

    await dispatch(getContent(subtitleContentId, null, 'compare_to', null))
      .then(async (result) => {
        const subtitlesId = config.settings.subtitlesId;
        const { presigned_url } = await getSubtitlesPresignedUrl(
          parentUid,
          result?.[subtitlesId],
        );

        if (presigned_url) {
          const { text } = await request.get(`${presigned_url}`);

          if (text) {
            parser.parse(text);
            parser.flush();
          }
        }
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error));
  };

  return (
    <div data-vjs-player>
      <div
        ref={videoRef}
        className={cx({
          unselected: selected === false,
        })}
      />
    </div>
  );
};

export default VideoJS;
