import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Body from '../Blocks/VideoJS/Body';
import { Api } from '@plone/volto/helpers';
import { MEDIA_PAGE_WOWZA_ROUTE } from 'volto-base-addon/constants';
import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import mime from 'mime-types';

const Embed = () => {
  const { token } = useSelector((state) => state.userSession);

  // eslint-disable-next-line no-unused-vars
  const [audioPlayerData, setAudioPlayerData] = useState(null);
  const [videoPlayerData, setVideoPlayerData] = useState(null);
  const [videoBodyProps, setVideoBodyProps] = useState(null);

  const fetchMediaPageByUid = useCallback(
    async (uid) => {
      const api = new Api();
      try {
        const query = { UID: uid, fullobjects: true };
        const response = await api.get('/@search', {
          params: query,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response?.items?.length === 1 ? response.items[0] : null;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching main object:', error);
        return null;
      }
    },
    [token],
  );

  const fetchChildren = useCallback(
    async (path) => {
      const api = new Api();
      try {
        const childrenResponse = await api.get(`${path}/@search`, {
          params: { fullobjects: true },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return childrenResponse?.items || [];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching children:', error);
        return [];
      }
    },
    [token],
  );

  const fetchAndCreateBlobUrl = useCallback(async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching audio data:', error);
      return null;
    }
  }, []);

  const parseBlockData = (mediaPage) => {
    if (!mediaPage || !mediaPage.blocks) {
      return null;
    }
    return (
      Object.values(mediaPage.blocks).find(
        (block) => block['@type'] === 'mediapagevideojs',
      ) || null
    );
  };

  const fetchSubtitles = useCallback(
    async (children) => {
      const api = new Api();
      const subtitles = [];
      for (const item of children) {
        if (item['@type'] === 'subtitle') {
          // Only take subtitles marked as completed
          const workflow = await api.get(
            flattenToAppURL(item['@id']) + '/@workflow',
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          const state =
            workflow?.['chain']?.['media_output_workflow']?.['state']?.['id'];
          if (state && state !== 'completed') {
            continue;
          }
          subtitles.push([
            {
              '@id': new URL(item['@id']).pathname,
              title: item['title'],
              language: item['title'],
            },
          ]);
        }
      }
      return subtitles;
    },
    [token],
  );

  useEffect(() => {
    const updatePlayerData = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const uid = searchParams.get('uid');
      const type = searchParams.get('type') || 'video';
      const api = new Api();

      if (!uid) {
        // eslint-disable-next-line no-console
        console.error('UID not provided!');
        return;
      }

      const mediaPage = await fetchMediaPageByUid(uid);
      if (!mediaPage || mediaPage['@type'] !== 'media_page') {
        // eslint-disable-next-line no-console
        console.error('Media Page not found!');
        return;
      }

      const path = new URL(mediaPage['@id']).pathname;
      const children = await fetchChildren(path);

      if (type === 'audio') {
        const transcoding = children.find(
          (child) =>
            new URL(child['@id']).pathname.split('/').slice(-1)[0] === 'audio',
        );
        if (!transcoding) {
          // eslint-disable-next-line no-console
          console.error('Audio content not found!');
          return;
        }

        const url = flattenToAppURL(mediaPage['@id']) + '/@download-presigned';
        const resp = await api.post(url, {
          data: {
            s3_object_id: transcoding['s3_object_id'],
            s3_object_path: uid,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const blobUrl = await fetchAndCreateBlobUrl(resp.presigned_url);
        setAudioPlayerData({ src: blobUrl });
      } else {
        const subtitles = await fetchSubtitles(children);
        const blockData = parseBlockData(mediaPage);

        // Get the stream URL
        const resp = await fetch(
          `${config.settings.publicPath}/${MEDIA_PAGE_WOWZA_ROUTE}/${uid}/${mediaPage.s3_object_id}`,
        );
        const jsonData = await resp.json();

        const url = jsonData['url'];

        const mediaData = {
          sources: [
            {
              src: url,
              type: mime.lookup(url),
            },
          ],
          subtitles: subtitles,
        };

        const data = {
          ...blockData,
          options: {
            ...blockData.options,
            ...mediaData,
          },
        };
        setVideoPlayerData(data);
        setVideoBodyProps({
          parentUid: mediaPage.UID,
          parentType: mediaPage['@type'],
          parentItems: children,
        });
      }
    };

    updatePlayerData();
  }, [
    token,
    fetchMediaPageByUid,
    fetchChildren,
    fetchAndCreateBlobUrl,
    fetchSubtitles,
  ]);

  return (
    <div id="page-embed">
      <div id="player-container">
        {videoPlayerData && (
          <div className="block video align center">
            <Body {...videoBodyProps} data={videoPlayerData} editMode={false} />
          </div>
        )}
        {audioPlayerData?.src && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <audio controls>
            <source src={audioPlayerData.src} type="audio/mp4" />
          </audio>
        )}
      </div>
    </div>
  );
};

export default Embed;
