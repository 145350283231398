import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, Component } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { MEDIA_PAGE_PREVIEW_ROUTE } from 'volto-base-addon/constants';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import request from 'superagent';
import config from '@plone/volto/registry';
import { Loader } from 'semantic-ui-react';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';

const MediaPageSummaryTemplate = ({
  items,
  linkTitle,
  linkHref,
  isEditMode,
}) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';
  const [ready, setReady] = useState(false);
  const [preview, setPreview] = useState({ id: -1, isOn: false });

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <a href={href}>{linkTitle || href}</a>;
  }

  useEffect(() => {
    if (!ready) {
      (async () => {
        for (const item of items) {
          if (
            item['@type'] === 'media_page' &&
            item.hasOwnProperty('s3_image_preview') &&
            item.hasOwnProperty('s3_video_preview')
          ) {
            for (const value of Object.values(item.blocks)) {
              if (value['@type'] === 'mediapagevideojs' && value['poster']) {
                item['poster'] = value['poster'];
              }
            }
            try {
              if (
                item.s3_image_preview &&
                !item.s3_image_preview.includes('http')
              ) {
                const { body: imagePreview } = await request.get(
                  `${config.settings.publicPath}/${MEDIA_PAGE_PREVIEW_ROUTE}/${item.UID}/${item.s3_image_preview}`,
                );
                item.s3_image_preview = imagePreview['presigned_url'];
              }
              if (
                item.s3_video_preview &&
                !item.s3_video_preview.includes('http')
              ) {
                const { body: videoPreview } = await request.get(
                  `${config.settings.publicPath}/${MEDIA_PAGE_PREVIEW_ROUTE}/${item.UID}/${item.s3_video_preview}`,
                );
                item.s3_video_preview = videoPreview['presigned_url'];
              }
            } catch (e) {
              // eslint-disable-next-line no-console
              console.log(e);
            }
          }
        }
        setReady(true);
      })();
    }
  });

  return (
    <>
      <div className="items mediapage-summary-template">
        {ready ? (
          items.map((item) => (
            <div className="listing-item" key={item['@id']}>
              <ConditionalLink item={item} condition={!isEditMode}>
                {item['@type'] === 'media_page' ? (
                  <>
                    {item['poster'] ? (
                      <div className="mediapage-item">
                        <div className="mediapage-img-wrapper">
                          <img
                            className="mediapage-preview-img"
                            src={`data:${item.poster['content-type']};base64,${item.poster['data']}`}
                            alt={`${item.title} preview`}
                          ></img>
                        </div>
                        <div className="listing-body">
                          <h3>{item.title || item.id}</h3>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    ) : (
                      <>
                        {/* Only image preview  */}
                        {item.s3_image_preview && !item.s3_video_preview && (
                          <div className="mediapage-item">
                            <div className="mediapage-img-wrapper">
                              <img
                                className="mediapage-preview-img"
                                src={item.s3_image_preview}
                                alt={`${item.title} preview`}
                              ></img>
                            </div>
                            <div className="listing-body">
                              <h3>{item.title || item.id}</h3>
                              <p>{item.description}</p>
                            </div>
                          </div>
                        )}
                        {/* Only video preview  */}
                        {!item.s3_image_preview && item.s3_video_preview && (
                          <div
                            className="mediapage-item"
                            onMouseEnter={() => {
                              setPreview({ id: item['@id'], isOn: true });
                            }}
                            onMouseLeave={() =>
                              setPreview({ id: -1, isOn: false })
                            }
                          >
                            <div className="mediapage-img-wrapper">
                              {preview.id === item['@id'] && preview.isOn ? (
                                <img
                                  className="mediapage-preview-img"
                                  src={item.s3_video_preview}
                                  alt={`${item.title} preview`}
                                ></img>
                              ) : (
                                <img
                                  className="mediapage-preview-img"
                                  src={DefaultImageSVG}
                                  alt={`${item.title} preview`}
                                ></img>
                              )}
                            </div>
                            <div className="listing-body">
                              <h3>{item.title || item.id}</h3>
                              <p>{item.description}</p>
                            </div>
                          </div>
                        )}
                        {/* Neither image nor video  */}
                        {!item.s3_image_preview && !item.s3_video_preview && (
                          <div className="mediapage-item">
                            <div className="mediapage-img-wrapper">
                              <img
                                className="mediapage-preview-img"
                                src={DefaultImageSVG}
                                alt={`${item.title} preview`}
                              ></img>
                            </div>
                            <div className="listing-body">
                              <h3>{item.title || item.id}</h3>
                              <p>{item.description}</p>
                            </div>
                          </div>
                        )}
                        {item.s3_image_preview && item.s3_video_preview && (
                          <div
                            className="mediapage-item"
                            onMouseEnter={() => {
                              setPreview({ id: item['@id'], isOn: true });
                            }}
                            onMouseLeave={() =>
                              setPreview({ id: -1, isOn: false })
                            }
                          >
                            <div className="mediapage-img-wrapper">
                              {preview.id === item['@id'] && preview.isOn ? (
                                <img
                                  className="mediapage-preview-img"
                                  src={item.s3_video_preview}
                                  alt={`${item.title} preview`}
                                ></img>
                              ) : (
                                <img
                                  className="mediapage-preview-img"
                                  src={item.s3_image_preview}
                                  alt={`${item.title} preview`}
                                ></img>
                              )}
                            </div>
                            <div className="listing-body">
                              <h3>{item.title || item.id}</h3>
                              <p>{item.description}</p>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <div className="mediapage-item">
                    <div className="mediapage-img-wrapper">
                      <Component
                        componentName="PreviewImage"
                        item={item}
                        alt=""
                        className="mediapage-preview-img"
                      />
                    </div>
                    <div className="listing-body">
                      <h3>{item.title || item.id}</h3>
                      <p>{item.description}</p>
                    </div>
                  </div>
                )}
              </ConditionalLink>
            </div>
          ))
        ) : (
          <>
            <p> Loading.. </p>
            <Loader active inline />
          </>
        )}
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};

MediaPageSummaryTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default MediaPageSummaryTemplate;
