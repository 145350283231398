import React, { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { EPIC_FILE_UPLOAD } from 'volto-base-addon/constants';
import { startEpic } from 'volto-epics-addon/helpers';

const View = (props) => {
  const types = useSelector((state) => state.types.types);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Check if the media page is addable here (the user has permissions etc)
    try {
      setVisible(
        types.filter((type) => type.title === 'Media Page')[0].addable,
      );
    } catch (err) {
      // Perhaps not logged but get the page via cache
    }
  }, [types]);

  const uploadButtonHandler = () => {
    // Let's start a new upload process!
    let destinationPath = props.path;
    startEpic(EPIC_FILE_UPLOAD, {
      destinationPath: destinationPath,
    });
  };

  return visible ? (
    <div className="upload-video-button-container block">
      <Button onClick={uploadButtonHandler} color="blue">
        Upload video or audio
      </Button>
    </div>
  ) : null;
};

export default View;
