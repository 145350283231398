import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Form,
  Header,
  Input,
  TextArea,
} from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { EPICS_PATH_CONTINUE } from 'volto-epics-addon/constants';
import { TASK_UPLOAD_BASICINFO_PROMPT } from 'volto-base-addon/constants';
import { completeTask, useTask } from 'volto-epics-addon/helpers';
import UploadStepsContainer from '@package/components/theme/FileUpload/UploadStepsContainer';

const Page = () => {
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const task = useTask(TASK_UPLOAD_BASICINFO_PROMPT);
  const token = useSelector((state) => state.userSession.token);

  useEffect(() => {
    setIsAuthenticated(!!token);
    setIsVisible(true);
  }, [token]);

  useEffect(() => {
    if (task?.completed === true) {
      history.push(EPICS_PATH_CONTINUE);
    }
  }, [task, history]);

  const onSubmit = async (ev) => {
    if (!token) return;

    ev.nativeEvent.preventDefault();

    try {
      if (!title) {
        // eslint-disable-next-line no-alert
        alert('You must set a title.');
        return;
      }
      if (!description) {
        // eslint-disable-next-line no-alert
        alert('You must set a description.');
        return;
      }
      completeTask(TASK_UPLOAD_BASICINFO_PROMPT, {
        title: title,
        description: description,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  return isVisible ? (
    isAuthenticated ? (
      <Container className="basicinfo-prompt-container">
        <UploadStepsContainer currentStep={2} totalSteps={4} />
        <Header> Basic info </Header>
        <Form onSubmit={onSubmit}>
          <Form.Field
            onChange={(e) => setTitle(e.target.value)}
            label="Title"
            control={Input}
            required
          ></Form.Field>
          <Form.Field
            onChange={(e) => setDescription(e.target.value)}
            label="Description"
            control={TextArea}
            required
          ></Form.Field>
          <Button style={{ marginTop: '1em' }} floated="right" type="submit">
            Submit
          </Button>
        </Form>
      </Container>
    ) : (
      <Container className="basicinfo-prompt-container">
        <Header className="ui header"> You must log in first. </Header>
      </Container>
    )
  ) : (
    <Container className="basicinfo-prompt-container"></Container>
  );
};

export default Page;
