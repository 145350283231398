import React, { useEffect, useState } from 'react';
import { Progress, Container, Header } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { EPICS_PATH_CONTINUE } from 'volto-epics-addon/constants';
import { TASK_INTERMEDIATE_JOBS_VIEW } from 'volto-base-addon/constants';
import { useTaskPoll, useUIDVariablesPoll } from 'volto-epics-addon/helpers';
import UploadStepsContainer from '@package/components/theme/FileUpload/UploadStepsContainer';

const Page = () => {
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const task = useTaskPoll(1000, TASK_INTERMEDIATE_JOBS_VIEW);
  const [mediaPeageUid, setMediaPageUid] = useState('');
  const token = useSelector((state) => state.userSession.token);
  const [faceblurProgress, setFaceblurProgress] = useState(null);
  const variables = useUIDVariablesPoll(
    1000,
    mediaPeageUid,
    ['faceblurProgress'],
    mediaPeageUid !== '',
  );

  useEffect(() => {
    setIsAuthenticated(!!token);
    setIsVisible(true);
  }, [token]);

  useEffect(() => {
    if (task) {
      if (mediaPeageUid === '') {
        setMediaPageUid(task.variables.mediaPageUid);
      }
      if (task.variables.faceblurProgress) {
        setFaceblurProgress(task.variables.faceblurProgress);
      }
      if (task?.completed === true) {
        history.push(EPICS_PATH_CONTINUE);
      }
    }
  }, [task, history, mediaPeageUid]);

  useEffect(() => {
    if (variables) {
      for (let key in variables) {
        if ('faceblurProgress' in variables[key]) {
          setFaceblurProgress(JSON.parse(variables[key].faceblurProgress));
          break;
        }
      }
    }
  }, [variables]);

  return isVisible ? (
    isAuthenticated ? (
      <Container className="waitforjobs-view-container">
        <UploadStepsContainer currentStep={2} totalSteps={4} />
        {faceblurProgress ? (
          <div style={{ marginTop: '3em' }}>
            <Header>Applying face blur...</Header>
            <Progress
              percent={faceblurProgress.percentage}
              indicating
              progress
            />
          </div>
        ) : (
          <div style={{ marginTop: '3em' }}>
            <Header>Please wait while your data is being processed...</Header>
            <Progress percent={100} active />
          </div>
        )}
      </Container>
    ) : (
      <Container className="waitforjobs-view-container">
        <Header className="ui header"> You must log in first. </Header>
      </Container>
    )
  ) : (
    <Container className="waitforjobs-view-container"></Container>
  );
};

export default Page;
